
#states {
    fill: #aaa;
    stroke: #fff;
    stroke-width: 50px;
  }
  
  #states .active {
    fill: orange;
  }
  
  path.connection {
    stroke: blue;
    stroke-width: 2px;
    fill: none;
  }
  
.city{
  fill: yellowgreen;
}
.storemapper-iw-close{
  fill: yellowgreen;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

body {
  box-sizing: border-box;
  color: #220f5f;
  font-family: 'Poppins', sans-serif;
}

.container {
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 70px;  
}

.title {
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}

table {
  border-collapse: collapse;
  width: 100%;  
}

tr th {
  font-size: 18px;
  padding: 12px;
  text-align: left;
  background-color: rgba(207, 218, 15, 0.2);
}

input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  width: 91%;
  padding: 12px 12px;
  font-family: 'Poppins', sans-serif;
}

input:hover {
  background-color: #fffaf3;
}

input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}
