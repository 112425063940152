.suggestion{
    cursor: pointer;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 14px;
    text-align: left;
    
}
.suggestion:hover{
    background-color: grey;
}